<template>
  <DefaultLayout>
    <router-view></router-view>
  </DefaultLayout>
</template>

<script>
export default {
  name: "IllicitBarcode",
};
</script>
